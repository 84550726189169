import * as React from "react";
import { Box } from "@mui/material";

import { Footer } from "../Footer/Footer";
import { Head } from "../Head";

type MainLayoutProps = {
  children: React.ReactNode;
  title?: string;
  description?: string;
  route?: string;
};

export const MainLayout = ({
  children,
  title,
  description,
  route,
}: MainLayoutProps) => {
  return (
    <Box sx={{ background: "#000", maxWidth: "100vw" }}>
      <Head
        title={title || ""}
        description={description || ""}
        route={route || ""}
      />
      {children}
      <Footer />
    </Box>
  );
};
