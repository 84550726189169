import { createContext, useContext, useState, ReactNode } from "react";

interface IVideoPlaybackContext {
  playingVideoId: string | null;
  setPlayingVideoId: (id: string | null) => void;
}

const VideoPlaybackContext = createContext<IVideoPlaybackContext | null>(null);

interface VideoPlaybackProviderProps {
  children: ReactNode;
}

export const VideoPlaybackProvider: React.FC<VideoPlaybackProviderProps> = ({
  children,
}) => {
  const [playingVideoId, setPlayingVideoId] = useState<string | null>(null);

  const handleSetPlayingVideoId = (id: string | null) => {
    // Pause any other playing video
    if (id !== playingVideoId) {
      const currentlyPlayingVideoElement = document.getElementById(
        playingVideoId ?? ""
      ) as HTMLVideoElement | null;
      if (
        currentlyPlayingVideoElement &&
        !currentlyPlayingVideoElement.paused
      ) {
        currentlyPlayingVideoElement.pause();
      }
    }
    // Set the new playing video ID
    setPlayingVideoId(id);
  };

  return (
    <VideoPlaybackContext.Provider
      value={{ playingVideoId, setPlayingVideoId: handleSetPlayingVideoId }}
    >
      {children}
    </VideoPlaybackContext.Provider>
  );
};

export const useVideoPlayback = (): IVideoPlaybackContext => {
  const context = useContext(VideoPlaybackContext);
  if (!context) {
    throw new Error(
      "useVideoPlayback must be used within VideoPlaybackProvider"
    );
  }
  return context;
};
