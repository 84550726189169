import { Link } from "@mui/material";
import * as React from "react";

type LogoProps = {
  width?: string;
  imgSrc: string;
  style?: React.CSSProperties;
};

export const Logo = ({ width, imgSrc, style = {} }: LogoProps) => {
  return (
    <Link className="flex items-center" href="/">
      <img
        src={imgSrc}
        alt="Universal Plus Logo"
        className={""}
        style={{ width: `${width}px`, ...style }}
      />
    </Link>
  );
};
