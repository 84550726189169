import { Suspense } from "react";
import { Box, ThemeProvider } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";

import { VideoPlaybackProvider } from "../context/VideoContext";
import { ScrollToTop } from "../lib";
import ScriptLoader from "../utils/ScriptLoader";
import { useCustomTheme } from "../theme";

const handleRefreshClick = async () => {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister();
    });
  });

  caches.keys().then((keyList) => {
    return Promise.all(
      keyList.map((key) => {
        return caches.delete(key);
      })
    );
  });

  setTimeout(() => {
    window.location.reload();
  }, 1000);
};

const ErrorFallback = () => {
  return (
    <div
      className="w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h1 className="text-[24px] font-bold">Please bear with us..</h1>
      <p>
        Sorry for the inconvenience. We suggest you <b>refresh the page</b> to
        resolve the issue.
      </p>
      <button
        className="mt-4 py-2 px-4 bg-red-600 border text-white border-gray-300 disabled:opacity-70 disabled:cursor-not-allowed rounded-md shadow-sm font-medium focus:outline-none"
        onClick={handleRefreshClick}
      >
        Refresh
      </button>
    </div>
  );
};

const SuspenseFallback = (
  <Box
    sx={{
      width: "100vw",
      hegith: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      bgcolor: "#000",
    }}
    className="w-screen h-screen flex flex-col justify-center items-center"
    role="alert"
  >
    <p>Loading...</p>
  </Box>
);

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const theme = useCustomTheme();
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollToTop />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ScriptLoader
              src="https://cdn.cookielaw.org/consent/0109f4d4-6928-49f8-a800-4626a42c6a7f/otSDKStub.js"
              domainScript="0109f4d4-6928-49f8-a800-4626a42c6a7f"
              integrity="sha384-TKdmlzVmoD70HzftTw4WtOzIBL5mNx8mXSRzEvwrWjpIJ7FZ/EuX758yMDWXtRUN"
            />
            <Suspense fallback={SuspenseFallback}>
              <VideoPlaybackProvider>{children}</VideoPlaybackProvider>
            </Suspense>
          </ErrorBoundary>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
};
