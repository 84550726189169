import { useMemo } from "react";
import { createTheme } from "@mui/material";

import { HEADING_FONT_FAMILY } from "../constants";

const colors = {
  primary: "#000000",
  secondaryMain: "#49c3d1",
  secondaryLight: "#d5ecf4",
  CTABannerBackground: "#f9f9f9",
  grey: "#0000008F",
  text: "#ffffff",
};

// Define a custom hook that returns a theme object
export const useCustomTheme = () => {
  return useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily:
            "OpenSans-Regular, OpenSans-Light, OpenSans-Bold, Arial, sans-serif",
          h1: {
            fontFamily: HEADING_FONT_FAMILY,
            fontSize: "34px",
            color: colors.text,
          },
          h2: {
            fontFamily: HEADING_FONT_FAMILY,
            fontSize: "24px",
            color: colors.text,
            letterSpacing: ".2px",
          },
          h3: {
            fontFamily: HEADING_FONT_FAMILY,
            fontSize: "20px",
            color: colors.text,
            letterSpacing: ".2px",
          },
          h4: {
            fontFamily: HEADING_FONT_FAMILY,
            fontSize: "16px",
            color: colors.text,
          },
          h5: {
            fontFamily: HEADING_FONT_FAMILY,
            fontSize: "14px",
            color: colors.text,
          },
          h6: {
            fontFamily: HEADING_FONT_FAMILY,
            fontSize: "12px",
            color: colors.text,
          },
          subtitle1: {
            fontSize: "14px",
            color: colors.text,
            lineHeight: "1.4em",
          },
          subtitle2: { fontSize: "12px", color: colors.text },
          caption: {
            fontSize: "12px",
            color: colors.text,
            letterSpacing: ".2px",
            lineHeight: 1.2,
          },
          body1: {
            fontSize: "14px",
            lineHeight: "1.334em",
            color: colors.text,
          },
          body2: {
            letterSpacing: "0em",
            lineHeight: "1.5em",
            fontSize: "12px",
            color: colors.text,
          },
        },
        palette: {
          primary: { main: colors.primary, contrastText: colors.text },
          secondary: {
            main: colors.secondaryMain,
            light: colors.secondaryLight,
          },
          background: {
            paper: colors.CTABannerBackground,
            default: colors.primary,
          },
          common: { white: colors.text },
        },
      }),
    []
  );
};
