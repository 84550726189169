import * as React from "react";
import { Route, Routes } from "react-router-dom";

import Landing from "../pages/landing/routes";

const ContestRules = React.lazy(() => import("../pages/contestRules/routes"));
const GeneralConditions = React.lazy(
  () => import("../pages/generalConditions/routes")
);
const Legal = React.lazy(() => import("../pages/legal/routes"));

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/legal" element={<Legal />} />
      <Route path="/conditions" element={<GeneralConditions />} />
      <Route path="/rules" element={<ContestRules />} />
      <Route path="*" element={<Landing />} />
    </Routes>
  );
};
