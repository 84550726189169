import { useTheme } from "@mui/material/styles";
import { Box, Typography, useMediaQuery, Link, Button } from "@mui/material";

import { Logo } from "../Logo";
import LogoImage from "../../assets/images/logos/universal-plus.webp";
import {
  CTA_TEXT_BANNER_HEIGHT,
  FOOTER_LINK_TITLE,
  FOOTER_SECTION,
} from "../../constants";

interface LinkItem {
  text: string;
  link: string;
  external?: boolean;
  isCookiePreference?: boolean;
}

interface FooterLinkProps {
  item: LinkItem;
  alignment: "center" | "left";
}

const links: LinkItem[] = [
  {
    text: "Règlement des jeux concours",
    link: "/rules",
  },
  {
    text: "Mentions légales",
    link: "/legal",
  },
  {
    text: "Conditions Générales d’Utilisation",
    link: "/conditions",
  },
  {
    text: "Préférences en matière de cookies",
    link: "",
    isCookiePreference: true,
  },
  {
    text: "Choix d’annonce",
    link: "https://www.nbcuniversal.com/privacy-policy/d%c3%a9claration-relative-aux-cookies#accordionheader2",
    external: true,
  },
  {
    text: "Politique de confidentialité",
    link: "https://www.nbcuniversal.com/privacy-policies/privacy-french",
    external: true,
  },
];

const FooterLink = ({ item, alignment }: FooterLinkProps) => {
  const theme = useTheme();

  if (item.isCookiePreference) {
    return (
      <Button
        sx={{ p: "0px" }}
        id="ot-sdk-btn"
        className="ot-sdk-show-settings"
      >
        <Typography
          sx={{
            fontSize: "16px",
            color: theme.palette.primary.contrastText,
            "&:hover": {
              "& > .MuiTypography-root": {
                color: "#9e9e9e",
              },
            },
          }}
          variant="body1"
        >
          {item.text}
        </Typography>
      </Button>
    );
  }

  return (
    <Link
      href={item.link}
      target={item.external ? "_blank" : "_self"}
      rel={item.external ? "noreferrer" : ""}
      sx={{
        textDecoration: "none",
        p: "8px 0 0 0",
        fontSize: "16px",
        textAlign: alignment,
        color: item.external ? theme.palette.primary.contrastText : "inherit",
        "&:hover": {
          "& > .MuiTypography-root": {
            color: "#9e9e9e",
          },
        },
      }}
    >
      <Typography sx={{ fontSize: "16px" }} variant="body1">
        {item.text}
      </Typography>
    </Link>
  );
};

export const Footer = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const alignment = matchDownMd ? "center" : "left";

  return (
    <Box
      component="footer"
      id={FOOTER_SECTION}
      sx={{
        color: theme.palette.primary.contrastText,
        minHeight: `calc(100vh - ${CTA_TEXT_BANNER_HEIGHT}})`,
        bgcolor: theme.palette.primary.main,
        p: matchDownMd ? "16px 16px 84px 16px" : "64px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: matchDownMd ? "center" : "start",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ textAlign: alignment }} gutterBottom variant="h3">
            {FOOTER_LINK_TITLE}
          </Typography>

          {links.map((item, index) => (
            <FooterLink key={index} item={item} alignment={alignment} />
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: "32px 0",
        }}
      >
        <Box sx={{ p: "32px 0" }}>
          <Logo imgSrc={LogoImage} width={matchDownMd ? "120" : "200"} />
        </Box>

        <Box sx={{ textAlign: "center", width: "80%" }}>
          <Typography variant="body2" sx={{ color: "#9e9e9e", pb: "16px" }}>
            {`© ${new Date().getFullYear()} NBCUniversal. Tous les droits sont réservés.`}
          </Typography>
          <Typography variant="body2" sx={{ color: "#9e9e9e", pb: "12px" }}>
            Contactez-nous par email :{" "}
            <Link
              href="mailto:communication_fr@nbcuni.com"
              sx={{
                color: theme.palette.secondary.main,
                textDecoration: "underline",
              }}
            >
              communication_fr@nbcuni.com
            </Link>
          </Typography>
          <Typography variant="body2" sx={{ color: "#9e9e9e", pb: "12px" }}>
            Syfy, E ! et Dreamworks sont édités par NBC UNIVERSAL GLOBAL
            NETWORKS ESPANA S.L.U. EDIFICIO TORRE EUROPA, PASEO DE LA
            CASTELLANA, 95 – PLANTA 10, 28046 MADRID, Espagne RCS : B-82227893
            MADRID
          </Typography>
          <Typography variant="body2" sx={{ color: "#9e9e9e", pb: "12px" }}>
            13ème Rue est éditée par NBC Universal Global Networks France SAS,
            29 Boulevard des Italiens, 75002 Paris
          </Typography>
          <Typography variant="body2" sx={{ color: "#9e9e9e", pb: "12px" }}>
            13ème Rue est réglementée en France par l’Autorité de régulation de
            la communication audiovisuelle et numérique (ARCOM)
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
