import { useState, useEffect, useCallback } from "react";

interface ScriptLoaderProps {
  src: string;
  type?: string;
  charset?: string;
  async?: boolean;
  defer?: boolean;
  documentLanguage?: string;
  domainScript: string;
  integrity: string;
  crossOrigin?: string;
}

const ScriptLoader = ({
  src,
  type = "text/javascript",
  charset = "UTF-8",
  async = true,
  defer = true,
  documentLanguage = "true",
  domainScript,
  integrity,
  crossOrigin = "anonymous",
}: ScriptLoaderProps) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [worker, setWorker] = useState<Worker | null>(null);

  const loadScript = useCallback(() => {
    if (scriptLoaded) return; // Prevent multiple executions if script is already loaded

    const script = document.createElement("script");
    script.src = src;
    script.async = async;
    script.defer = defer;
    script.type = type;
    script.charset = charset;
    script.integrity = integrity;
    script.crossOrigin = crossOrigin;
    script.setAttribute("data-document-language", documentLanguage);
    script.setAttribute("data-domain-script", domainScript);

    document.body.appendChild(script);
    setScriptLoaded(true); // Update the state to indicate the script has loaded
  }, [
    scriptLoaded,
    src,
    async,
    defer,
    type,
    charset,
    integrity,
    crossOrigin,
    documentLanguage,
    domainScript,
  ]);

  const cleanup = useCallback(() => {
    window.removeEventListener("click", loadScript);
    window.removeEventListener("scroll", loadScript);
    if (worker) {
      worker.terminate(); // Terminate the worker when the script is loaded or component is unmounted
      setWorker(null);
    }
  }, [loadScript, worker]);

  useEffect(() => {
    // This useEffect is dedicated to setting up and managing the worker
    const newWorker = new Worker(`${process.env.PUBLIC_URL}/worker.js`);
    setWorker(newWorker);
    newWorker.postMessage({ type: "startTimer", delay: 7000 });

    newWorker.onmessage = (event) => {
      if (event.data.type === "timeout" && !scriptLoaded) {
        loadScript();
      }
    };

    return () => {
      if (newWorker) {
        newWorker.terminate();
      }
    };
  }, [scriptLoaded, loadScript]); // Depends on scriptLoaded to stop timeout when script is loaded

  useEffect(() => {
    // This useEffect is for adding event listeners
    window.addEventListener("click", loadScript);
    window.addEventListener("scroll", loadScript, { passive: true });

    return () => cleanup();
  }, [loadScript]);

  useEffect(() => {
    if (scriptLoaded) {
      cleanup(); // Perform cleanup once the script is loaded
    }
  }, [scriptLoaded, cleanup]); // React on scriptLoaded changes to perform cleanup

  return null;
};

export default ScriptLoader;
