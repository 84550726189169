import {
  Box,
  useMediaQuery,
  useTheme,
  useScrollTrigger,
  Fade,
} from "@mui/material";
import { lazy, memo } from "react";

import { MainLayout } from "../../../components/Layout";
import MosaicSection from "../components/MosaicSection";
import { HEADER_TEXT } from "../../../constants";

const Header = lazy(() => import("../../../components/Header/Header"));
const CTATextBanner = lazy(
  () => import("../../../components/CTATextBanner/CTATextBanner")
);
const CTATextBannerMini = lazy(
  () => import("../../../components/CTATextBannerMini/CTATextBannerMini")
);
const FaqSection = lazy(() => import("../components/Faq"));
const MonthlySelection = lazy(
  () => import("../components/MonthlySelection/MonthlySelection")
);
const OriginalProductions = lazy(
  () => import("../components/OriginalProductions")
);
const ShowCarouselSection = lazy(
  () => import("../components/ShowCarouselSection")
);
const ShowCaseSection = lazy(
  () => import("../components/ShowCaseSection/ShowCaseSection")
);
const SocialSection = lazy(() => import("../components/SocialSection"));

const MemoizedHeader = memo(Header);
const MemoizedMosaicSection = memo(MosaicSection);
const MemoizedCTATextBanner = memo(CTATextBanner);
const MemoizedCTATextBannerMini = memo(CTATextBannerMini);
const MemoizedFaqSection = memo(FaqSection);
const MemoizedMonthlySelection = memo(MonthlySelection);
const MemoizedOriginalProductions = memo(OriginalProductions);
const MemoizedShowCarouselSection = memo(ShowCarouselSection);
const MemoizedShowCaseSection = memo(ShowCaseSection);
const MemoizedSocialSection = memo(SocialSection);

const Landing = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const video = "/videos/showcase/21x9/output.mpd";
  const videoMobile = "/videos/showcase/4x5/output.mpd";

  const videoSrc = matchDownMd ? videoMobile : video;

  const hasScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 70,
  });

  return (
    <MainLayout
      title={"Universal+ France | Séries et films en live et en streaming​"}
      description={
        "Profitez de séries, films et plus encore : un vaste catalogue de contenus en live et en streaming !"
      }
      route={"/"}
    >
      <Box sx={{ width: "100%", height: "100%", bgcolor: "#000" }} id="main">
        <MemoizedMosaicSection />
        <MemoizedMonthlySelection />
        <MemoizedShowCarouselSection />
        <MemoizedOriginalProductions />
        <MemoizedShowCaseSection videoSrc={videoSrc} />
        <MemoizedSocialSection />
        <MemoizedFaqSection />

        {(hasScrolled || matchDownMd) && (
          <Fade
            in={hasScrolled || matchDownMd}
            timeout={500}
            style={{ width: "100%" }}
          >
            <Box sx={{ position: "relative", zIndex: 500, width: "100%" }}>
              <MemoizedHeader title1={HEADER_TEXT} />
            </Box>
          </Fade>
        )}

        {!matchDownMd && hasScrolled && (
          <Fade in={hasScrolled} timeout={500} style={{ width: "100%" }}>
            <Box sx={{ position: "relative", zIndex: 500, width: "100%" }}>
              <MemoizedCTATextBanner />
            </Box>
          </Fade>
        )}

        {matchDownMd && hasScrolled && (
          <Fade in={hasScrolled} timeout={500} style={{ width: "100%" }}>
            <Box sx={{ position: "relative", zIndex: 500, width: "100%" }}>
              <MemoizedCTATextBannerMini />
            </Box>
          </Fade>
        )}
      </Box>
    </MainLayout>
  );
};

export default Landing;
